<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <KTCard>
          <template v-slot:title>
            {{ formFields.id ? `Edit ${ operationTitle }` : `Add ${ operationTitle }` }}
          </template>
          <template v-slot:toolbar>
            <b-button class="mr-2 mt-3" @click="handleSubmitOperation"
                      size="sm"
                      variant="primary"
                      :disabled="global.pendingRequests > 0"
            >
              <i v-show="global.pendingRequests > 0"
                 class="fa fa-spin fa-spinner"></i>
              <i class="fa fa-save fa-sm"></i>
              Save
            </b-button>
            <b-button variant="danger" size="sm" class="mt-3"
                      @click="handleOperationClose()">
              <i class="fa fa-arrow-left fa-sm"></i> Cancel
            </b-button>
          </template>
          <template v-slot:body>
            <v-card>
              <div class="halls-operation">
                <b-container fluid>
                  <form @submit.prevent="handleSubmitOperation" autocomplete="off">
                    <b-row>
                      <b-col class="p-0" cols="12" md="12" lg="12" sm="12">
                        <b-row>
                          <b-col sm="6">
                            <b-form-group
                              label="Name Of Hall *"
                              label-for="name"
                              :invalid-feedback="formErrors.first('name')"
                            >
                              <b-form-input
                                id="name"
                                v-model="formFields.name"
                                type="text"
                                :state="((formErrors.has('name') ? false : null))"
                              ></b-form-input>
                            </b-form-group>
                          </b-col><!--/b-col-->
                          <b-col sm="6">
                            <b-form-group
                              label="Select Users"
                              label-for="user_id"
                              :invalid-feedback="formErrors.first('user_id')"
                            >
                              <treeselect
                                :multiple="true"
                                id="sub_users"
                                label="label"
                                :options="dropdowns.subUsers"
                                v-model="formFields.user_id"
                                placeholder=""
                              >
                              </treeselect>
                              <b-form-invalid-feedback
                                :state="(!(formErrors.has('user_id')))">
                                {{ formErrors.first('user_id') }}
                              </b-form-invalid-feedback>
                            </b-form-group>
                          </b-col><!--/b-col-->
                          <!--                                                    <b-col sm="6">-->
                          <!--                                                        <b-form-group-->
                          <!--                                                            label="Rent *"-->
                          <!--                                                            label-for="rent"-->
                          <!--                                                            :invalid-feedback="formErrors.first('rent')"-->
                          <!--                                                        >-->
                          <!--                                                            <b-form-input-->
                          <!--                                                                id="rent"-->
                          <!--                                                                v-numericOnly.keyup-->
                          <!--                                                                v-model="formFields.rent"-->
                          <!--                                                                type="text"-->
                          <!--                                                                :state="((formErrors.has('rent') ? false : null))"-->
                          <!--                                                            ></b-form-input>-->
                          <!--                                                        </b-form-group>-->
                          <!--                                                    </b-col>&lt;!&ndash;/b-col&ndash;&gt;-->
                        </b-row><!--/b-row-->
                        <b-row>
                          <b-col sm="4">
                            <b-form-group
                              label="Minimum Capacity *"
                              label-for="min_capacity"
                              :invalid-feedback="formErrors.first('min_capacity')"
                            >
                              <b-form-input
                                id="min_capacity" v-numericOnly.keyup
                                v-model="formFields.min_capacity"
                                type="text"
                                :state="((formErrors.has('min_capacity') ? false : null))"
                              ></b-form-input>
                            </b-form-group>
                          </b-col><!--/b-col-->
                          <b-col sm="4">
                            <b-form-group
                              label="Maximum Capacity *"
                              label-for="max_capacity"
                              :invalid-feedback="formErrors.first('max_capacity')"
                            >
                              <b-form-input
                                id="max_capacity" v-numericOnly.keyup
                                v-model="formFields.max_capacity"
                                type="text"
                                :state="((formErrors.has('max_capacity') ? false : null))"
                              ></b-form-input>
                            </b-form-group>
                          </b-col><!--/b-col-->
                          <b-col sm="4">
                            <b-form-group
                              label="Order"
                              label-for="order"
                              :invalid-feedback="formErrors.first('order')"
                            >
                              <b-form-input
                                id="max_capacity" v-numericOnly.keyup
                                v-model="formFields.order"
                                type="text"
                                :state="((formErrors.has('order') ? false : null))"
                              ></b-form-input>
                            </b-form-group>
                          </b-col><!--/b-col-->
                        </b-row>
                        <!--                                                <b-row>-->
                        <!--                                                    <b-col sm="6">-->
                        <!--                                                        <b-form-group-->
                        <!--                                                            label="SGST *"-->
                        <!--                                                            label-for="s_gst"-->
                        <!--                                                            :invalid-feedback="formErrors.first('s_gst')"-->
                        <!--                                                        >-->
                        <!--                                                            <b-form-input-->
                        <!--                                                                id="s_gst" v-numericOnly.keyup-->
                        <!--                                                                v-model="formFields.s_gst"-->
                        <!--                                                                type="text"-->
                        <!--                                                                :state="((formErrors.has('s_gst') ? false : null))"-->
                        <!--                                                            ></b-form-input>-->
                        <!--                                                        </b-form-group>-->
                        <!--                                                    </b-col>&lt;!&ndash;/b-col&ndash;&gt;-->
                        <!--                                                    <b-col sm="6">-->
                        <!--                                                        <b-form-group-->
                        <!--                                                            label="CGST *"-->
                        <!--                                                            label-for="c_gst"-->
                        <!--                                                            :invalid-feedback="formErrors.first('c_gst')"-->
                        <!--                                                        >-->
                        <!--                                                            <b-form-input-->
                        <!--                                                                id="c_gst" v-numericOnly.keyup-->
                        <!--                                                                v-model="formFields.c_gst"-->
                        <!--                                                                type="text"-->
                        <!--                                                                :state="((formErrors.has('c_gst') ? false : null))"-->
                        <!--                                                            ></b-form-input>-->
                        <!--                                                        </b-form-group>-->
                        <!--                                                    </b-col>&lt;!&ndash;/b-col&ndash;&gt;-->
                        <!--                                                </b-row>-->
                        <b-row>
                          <!--# Start Hall slot features #-->

                          <span class="text-danger"
                                v-if="formErrors.has('hall_slots')">
                                                            {{ formErrors.first('hall_slots') }}
                                                        </span>

                          <span class="text-danger"
                                v-if="formErrors.has('start_time')">
                                                            {{ formErrors.first("start_time") }}
                                                        </span>

                          <span class="text-danger"
                                v-if="formErrors.has('end_time')">
                                                            {{ formErrors.first('end_time') }}
                                                        </span>


                          <b-col lg="12" md="12" sm="12">
                            <b-card class="mb-0">
                              <b-card-header v-b-toggle.hall-slots class="p-0">
                                <h4 class="mb-0">
                                                            <span class="badge badge-primary">
                                                                Add Hall Slots
                                                            </span>
                                </h4>
                              </b-card-header><!-- /.p-0-->
                              <b-collapse
                                id="hall-slots">
                                <div class="bg-light p-3">
                                  <b-row>
                                    <b-col lg="2" md="2" sm="12">
                                      <b-form-group
                                        label="Slots *"
                                        label-for="slots">
                                        <treeselect
                                          id="slots"
                                          label="label"
                                          :options="[{id:'Full Day',label:'Full Day'},{id:'Morning',label:'Morning'},{id:'Evening',label:'Evening'},{id:'Afternoon',label:'Afternoon'},{id:'Breakfast',label:'Breakfast'},{id:'Hightea',label:'Hightea'}]"
                                          v-model="hall_slot.slot"
                                          placeholder=""
                                        >
                                        </treeselect>

                                        <b-form-invalid-feedback
                                          :state="(!(formErrors.has('slot')))">
                                          {{ formErrors.first('slot') }}
                                        </b-form-invalid-feedback>
                                      </b-form-group>
                                    </b-col><!--/b-col-->
                                    <b-col lg="3" md="3" sm="12">
                                      <b-form-group
                                        label="Start Time"
                                        label-for="start_time ">
                                        <treeselect
                                          :options="_.map(dropdowns.fromTime,(i) => {
                                              return {
                                                  id:i,
                                                  label:i
                                              }
                                          })"
                                          v-model="hall_slot.start_time"
                                        />
                                      </b-form-group>
                                    </b-col><!--/b-col-->
                                    <b-col lg="3" md="3" sm="12">
                                      <b-form-group
                                        label="End Time"
                                        label-for="end_time">
                                        <treeselect
                                          :options="_.map(dropdowns.toTime,(i) => {
                                              return {
                                                  id:i,
                                                  label:i
                                              }
                                          })"
                                          v-model="hall_slot.end_time"
                                        />
                                      </b-form-group>
                                    </b-col><!--/b-col-->
                                    <b-col lg="1" md="3" sm="12">
                                      <b-form-group
                                        label="Status"
                                        label-for="status">
                                        <b-form-checkbox
                                          class="pull-left mr-2"
                                          v-model="hall_slot.status"
                                          name="check-button"
                                          switch>
                                        </b-form-checkbox>
                                        <template
                                          v-if="hall_slot.status > 0">
                                          Active
                                        </template>
                                        <template v-else>DeActive</template>
                                      </b-form-group>
                                    </b-col>
                                    <b-col lg="2" md="2" sm="12">
                                      <div class="form-group">
                                        <label class="d-block">
                                          <pre> </pre>
                                        </label>
                                        <b-button variant="primary"
                                                  type="button"
                                                  size="sm"
                                                  class="btn-block"
                                                  @click="handleAddUpdateSlotFeatureClick()">
                                          Add Slot
                                        </b-button><!--/b-button-->
                                      </div><!-- /.form-group -->
                                    </b-col><!--/b-col-->
                                    <b-col lg="1" md="2" sm="12">
                                      <div class="form-group">
                                        <label class="d-block">
                                          <pre> </pre>
                                        </label>
                                        <b-button variant="warning"
                                                  type="button"
                                                  size="sm"
                                                  class="btn-block"
                                                  @click="resetSlotFeature()">
                                          Reset
                                        </b-button><!--/b-button-->
                                      </div><!-- /.form-group -->
                                    </b-col><!--/b-col-->
                                  </b-row><!--/b-row-->
                                  <b-row>
                                    <b-col cols="12">
                                      <table
                                        class="table table-bordered bg-white">
                                        <thead>
                                          <tr>
                                            <th width="50">#</th>
                                            <th width="80">Slot</th>
                                            <th width="180">Start Time</th>
                                            <th width="180">End Time</th>
                                            <th width="60">Status</th>
                                            <th width="60">Action</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr v-for="(cl, index) in hall_slots"
                                              :key="index"
                                              :class="[{'table-primary': cl.token === hall_slot.token}]">
                                            <td>{{ index + 1 }}</td>
                                            <td class="80">{{ cl.slot }}
                                            </td>
                                            <td>
                                              {{ $global.timeFormat(cl.start_time) }}
                                            </td>
                                            <td>
                                              {{ $global.timeFormat(cl.end_time) }}
                                            </td>
                                            <td>{{
                                                cl.status ? "Active" :
                                                  "DeActive"
                                              }}
                                            </td>
                                            <td>
                                              <a @click="handleEditSlotFeatureClick(cl.token)"
                                                 title="Edit Item"
                                                 v-b-tooltip.hover>
                                                  <span
                                                    class="svg-icon svg-icon-md svg-icon-primary">
                                                    <!--begin::Svg Icon-->
                                                    <inline-svg
                                                      src="/media/svg/icons/Communication/Write.svg"
                                                      class="action-edit-button"
                                                    ></inline-svg>
                                                    <!--end::Svg Icon-->
                                                  </span>
                                              </a>
                                              <a
                                                @click="handleDeleteSlotFeatureClick(cl)">
                                                <i slot="icon"
                                                   class="fe fe-trash"></i>
                                                <a class="ml-1"
                                                   title="Delete Item"
                                                   v-b-tooltip.hover>
                                                  <img
                                                    alt="delete icon"
                                                    src="../../../../public/media/icons/actionicon3.png"
                                                    class="action-delete-button" />
                                                </a>
                                              </a>
                                            </td>
                                          </tr>
                                        </tbody>
                                        <tfoot
                                          v-show="hall_slots.length <= 0">
                                          <tr>
                                            <th colspan="5">No data
                                              available
                                            </th>
                                          </tr>
                                        </tfoot>
                                      </table><!-- /.table table-bordered -->
                                    </b-col><!--/b-col-->
                                  </b-row><!--/b-row-->
                                </div>
                              </b-collapse>
                            </b-card>
                          </b-col>
                        </b-row>
                        <hr />
                        <b-row class="operation-footer">
                          <b-col sm="12">
                            <b-button
                              size="sm"
                              type="submit"
                              variant="primary"
                              :disabled="global.pendingRequests > 0"
                            >
                              <i v-show="global.pendingRequests > 0"
                                 class="fa fa-spin fa-spinner"></i>
                              <i class="fa fa-save fa-sm"></i>
                              Save
                            </b-button>

                            <b-button variant="danger" class="ml-3"
                                      size="sm"
                                      @click="handleOperationClose()">
                              <i class="fa fa-arrow-left fa-sm mr-1"></i> Cancel
                            </b-button>
                          </b-col>
                        </b-row>
                      </b-col><!--/b-col-->
                    </b-row><!--/b-row-->
                  </form><!--/form-->
                </b-container><!--/b-container-->
              </div><!--/.halls-operation-->
            </v-card>
          </template>
        </KTCard>
      </div>
    </div>
  </div>
</template>

<script>
import KTCard from "@/view/content/Card.vue";
import moduleMixin from "./module.mixin";
import { mapGetters, mapState } from "vuex";

export default {
  mixins: [moduleMixin],
  components: {
    KTCard,
  },
  mounted() {
    if ( this.$route.params && this.$route.params.id ) {
      this.handleEditOperation(this.$route.params.id);
    }

    this.getCountry();
    this.getState();
    this.getSubUsers();
  },
  computed: {
    ...mapState([
      'global',
    ]),
    ...mapGetters(['getActiveProperty']),
  },
};
</script>
